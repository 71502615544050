// src/components/pages/Profile.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../../assets/css/profile.css';

const Profile = ({ addNotification }) => {
    const [users, setUsers] = useState([]);
    const [filters, setFilters] = useState({
        fio: '',
        group: '',
        sport: '',
        bsp: '',
    });
    const [groups, setGroups] = useState([]);
    const [sports, setSports] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [editing, setEditing] = useState(false);
    const [userInfo, setUserInfo] = useState({});
    const [documents, setDocuments] = useState({
        passport: null,
        birthCertificate: null,
    });
    const [selectedDocument, setSelectedDocument] = useState('passport');

    const [currentPage, setCurrentPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(() => {
        const storedValue = localStorage.getItem('itemsPerPage');
        return storedValue ? JSON.parse(storedValue) : 15;
    });

    // Fetch users when filters change
    useEffect(() => {
        fetchUsers();
    }, [filters]);

    useEffect(() => {
        fetchGroups();
        fetchSports();
    }, []);

    const fetchUsers = async () => {
        try {
            // Prepare parameters; exclude bsp if its value is an empty string to include all results
            const params = { ...filters };
            if (params.bsp === '') {
                delete params.bsp; // Ignore bsp in the request if "БСП" is selected
            }
    
            const response = await axios.get('https://olimp.uor-ekb.ru/new_admin/get_users.php', { params });
            if (response.data.status === 'success') {
                setUsers(response.data.users);
            }
        } catch (error) {
            console.error('Ошибка при получении пользователей:', error);
        }
    };

    const fetchGroups = async () => {
        try {
            const response = await axios.get('https://olimp.uor-ekb.ru/new_admin/get_groups.php');
            if (response.data.status === 'success') {
                setGroups(response.data.groups);
            }
        } catch (error) {
            console.error('Ошибка при получении групп:', error);
        }
    };

    const fetchSports = async () => {
        try {
            const response = await axios.get('https://olimp.uor-ekb.ru/new_admin/get_sports.php');
            if (response.data.status === 'success') {
                setSports(response.data.sports);
            }
        } catch (error) {
            console.error('Ошибка при получении видов спорта:', error);
        }
    };

    const handleFilterChange = (e) => {
        setFilters({
            ...filters,
            [e.target.name]: e.target.value,
        });
        setCurrentPage(0); // Reset to first page when filters change
    };

    const openModal = async (user) => {
        setSelectedUser(user);
        setUserInfo(user);
        setShowModal(true);

        // Fetch user documents
        try {
            const response = await axios.get('https://olimp.uor-ekb.ru/new_admin/get_user_documents.php', {
                params: { userId: user.id },
            });
            if (response.data.status === 'success') {
                setDocuments({
                    passport: response.data.passport,
                    birthCertificate: response.data.birthCertificate,
                });
            }
        } catch (error) {
            console.error('Ошибка при получении документов пользователя:', error);
        }
    };

    const closeModal = () => {
        setShowModal(false);
        setSelectedUser(null);
        setEditing(false);
        setDocuments({ passport: null, birthCertificate: null });
    };

    const handleEditToggle = () => {
        setEditing((prev) => !prev);
    };

    const handleUserInfoChange = (e) => {
        setUserInfo({
            ...userInfo,
            [e.target.name]: e.target.value,
        });
    };

    const saveUserInfo = async () => {
        try {
            const response = await axios.post('https://olimp.uor-ekb.ru/new_admin/update_user.php', userInfo);
            if (response.data.status === 'success') {
                fetchUsers();
                setEditing(false);
                addNotification({
                    icon: 'success',
                    title: 'Успех',
                    subtitle: 'Данные пользователя успешно обновлены.',
                    actions: ['OK'],
                });
            } else {
                console.error(response.data.message);
                addNotification({
                    icon: 'error',
                    title: 'Ошибка',
                    subtitle: response.data.message || 'Ошибка обновления данных пользователя.',
                    actions: ['OK'],
                });
            }
        } catch (error) {
            console.error('Ошибка при обновлении пользователя:', error);
            addNotification({
                icon: 'error',
                title: 'Ошибка',
                subtitle: 'Ошибка при обновлении данных пользователя.',
                actions: ['OK'],
            });
        }
    };

    const deleteUser = async (id) => {
        if (window.confirm('Вы действительно хотите удалить этого пользователя?')) {
            try {
                const response = await axios.post('https://olimp.uor-ekb.ru/new_admin/delete_user.php', { id });
                if (response.data.status === 'success') {
                    setUsers(users.filter((user) => user.id !== id));
                    closeModal();
                    addNotification({
                        icon: 'success',
                        title: 'Успех',
                        subtitle: 'Пользователь успешно удалён.',
                        actions: ['OK'],
                    });
                } else {
                    console.error(response.data.message);
                    addNotification({
                        icon: 'error',
                        title: 'Ошибка',
                        subtitle: response.data.message || 'Ошибка удаления пользователя.',
                        actions: ['OK'],
                    });
                }
            } catch (error) {
                console.error('Ошибка при удалении пользователя:', error);
                addNotification({
                    icon: 'error',
                    title: 'Ошибка',
                    subtitle: 'Ошибка при удалении пользователя.',
                    actions: ['OK'],
                });
            }
        }
    };

    const totalPages = itemsPerPage === 'all' ? 1 : Math.ceil(users.length / itemsPerPage);

    const displayedUsers =
        itemsPerPage === 'all'
            ? users
            : users.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);

    const handlePageChange = (direction) => {
        setCurrentPage((prevPage) => {
            if (direction === 'next' && prevPage < totalPages - 1) {
                return prevPage + 1;
            }
            if (direction === 'prev' && prevPage > 0) {
                return prevPage - 1;
            }
            return prevPage;
        });
    };

    const handleItemsPerPageChange = (e) => {
        const value = e.target.value === 'Все' ? 'all' : parseInt(e.target.value);
        setItemsPerPage(value);
        localStorage.setItem('itemsPerPage', JSON.stringify(value));
        setCurrentPage(0);
    };

    return (
        <div className="profile-page">
            {/* Фильтры */}
            <div className="filter-section">
                <input
                    type="text"
                    name="fio"
                    placeholder="Поиск по ФИО"
                    value={filters.fio}
                    onChange={handleFilterChange}
                    className="search-input"
                />
                <select
                    name="group"
                    value={filters.group}
                    onChange={handleFilterChange}
                    className="search-input"
                >
                    <option value="">Группа</option>
                    {groups.map((group) => (
                        <option key={group.id} value={group.id}>
                            {group.name}
                        </option>
                    ))}
                </select>
                <select
                    name="sport"
                    value={filters.sport}
                    onChange={handleFilterChange}
                    className="search-input"
                >
                    <option value="">Вид спорта</option>
                    {sports.map((sport) => (
                        <option key={sport.id} value={sport.id}>
                            {sport.title}
                        </option>
                    ))}
                </select>
                <select
                    name="bsp"
                    value={filters.bsp}
                    onChange={handleFilterChange}
                    className="search-input"
                >
                    <option value="">БСП</option>
                    <option value="1">Да</option>
                    <option value="0">Нет</option>
                </select>
            </div>

            {/* Список пользователей */}
            <div className="users-list">
                {displayedUsers.map((user) => (
                    <div className="user-block" key={user.id} onClick={() => openModal(user)}>
                        <div className="user-info-container">
                            <img src={user.avatar} alt="Аватар" className="avatar" />
                            <div className="user-info">
                                <div className="user-name">{user.name}</div>
                                <div className="user-group">Группа: {user.group}</div>
                                <div className="user-details">
                                    {user.sport && <span>{user.sport}</span>}
                                    {user.telephone && <span>{user.telephone}</span>}
                                    {user.email && <span>{user.email}</span>}
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            {/* Пагинация */}
            <div className="pagination-container">
                <div className="pagination-left">
                    <span>Всего пользователей: {users.length}</span>
                </div>
                <div className="pagination-center">
                    <button
                        onClick={() => handlePageChange('prev')}
                        disabled={currentPage === 0}
                    >
                        &lt;
                    </button>
                    <span>
                        {currentPage + 1} / {totalPages}
                    </span>
                    <button
                        onClick={() => handlePageChange('next')}
                        disabled={currentPage === totalPages - 1}
                    >
                        &gt;
                    </button>
                </div>
                <div className="pagination-right">
                    <select
                        value={itemsPerPage === 'all' ? 'Все' : itemsPerPage}
                        onChange={handleItemsPerPageChange}
                    >
                        <option value="15">15</option>
                        <option value="30">30</option>
                        <option value="45">45</option>
                        <option value="60">60</option>
                        <option value="Все">Все</option>
                    </select>
                </div>
            </div>

            {/* Модальное окно */}
            {showModal && selectedUser && (
                <div className={`modal open`}>
                    <div className="modal-content">
                        <button className="close-button" onClick={closeModal}>
                            &times;
                        </button>
                        <div className="modal-body">
                            <div className="left-section">
                                <h2>Информация о пользователе</h2>
                                <div className="divider"></div>
                                <div className="user-profile">
                                    <img
                                        src={selectedUser.avatar}
                                        alt="Аватар"
                                        className="avatar-large"
                                    />
                                    <div className="user-main-info">
                                        <div className="user-name">{selectedUser.name}</div>
                                        {/* <div className="user-group">{selectedUser.group}</div> */}
                                    </div>
                                </div>
                                <div className="user-additional-info">
                                    {[
                                        'name',
                                        'group',
                                        'sport',
                                        'login',
                                        'password',
                                        'bsp',
                                        'role',
                                        'telephone',
                                        'email',
                                        'birthday',
                                    ].map((field) => (
                                        <div className="info-row" key={field}>
                                            <label>{getFieldLabel(field)}</label>
                                            {editing ? (
                                                field === 'group' ? (
                                                    <select
                                                        name="group"
                                                        value={userInfo.group || ''}
                                                        onChange={handleUserInfoChange}
                                                    >
                                                        {groups.map((group) => (
                                                            <option
                                                                key={group.id}
                                                                value={group.name}
                                                            >
                                                                {group.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                ) : field === 'sport' ? (
                                                    <select
                                                        name="sport"
                                                        value={userInfo.sport || ''}
                                                        onChange={handleUserInfoChange}
                                                    >
                                                        {sports.map((sport) => (
                                                            <option
                                                                key={sport.id}
                                                                value={sport.title}
                                                            >
                                                                {sport.title}
                                                            </option>
                                                        ))}
                                                    </select>
                                                ) : (
                                                    <input
                                                        type="text"
                                                        name={field}
                                                        value={userInfo[field] || ''}
                                                        onChange={handleUserInfoChange}
                                                    />
                                                )
                                            ) : (
                                                <span className='spancolor'>{userInfo[field]}</span>
                                            )}
                                        </div>
                                    ))}
                                </div>
                                <div className="action-buttons">
                                    {!editing ? (
                                        <button className="edit-button" onClick={handleEditToggle}>
                                            Редактировать
                                        </button>
                                    ) : (
                                        <button className="save-button" onClick={saveUserInfo}>
                                            Сохранить
                                        </button>
                                    )}
                                    <button
                                        className="delete-button"
                                        onClick={() => deleteUser(selectedUser.id)}
                                    >
                                        Удалить пользователя
                                    </button>
                                </div>
                            </div>
                            <div className="right-section">
                                <h2>Документы пользователя</h2>
                                <div className="document-buttons">
                                    {documents.passport && (
                                        <button
                                            className={
                                                selectedDocument === 'passport' ? 'active' : ''
                                            }
                                            onClick={() => setSelectedDocument('passport')}
                                        >
                                            Паспорт
                                        </button>
                                    )}
                                    {documents.birthCertificate && (
                                        <button
                                            className={
                                                selectedDocument === 'birthCertificate'
                                                    ? 'active'
                                                    : ''
                                            }
                                            onClick={() => setSelectedDocument('birthCertificate')}
                                        >
                                            Свидетельство о рождении
                                        </button>
                                    )}
                                </div>
                                <div className="document-content">
                                    {selectedDocument === 'passport' && documents.passport && (
                                        <div className="document-details">
                                            {Object.entries(documents.passport).map(
                                                ([key, value]) => (
                                                    <div key={key} className="document-row">
                                                        <label>{translateField(key)}</label>
                                                        <span className='spancolor'>{value}</span>
                                                    </div>
                                                )
                                            )}
                                        </div>
                                    )}
                                    {selectedDocument === 'birthCertificate' &&
                                        documents.birthCertificate && (
                                            <div className="document-details">
                                                {Object.entries(documents.birthCertificate).map(
                                                    ([key, value]) => (
                                                        <div key={key} className="document-row">
                                                            <label>{translateField(key)}</label>
                                                            <span className='spancolor'>{value}</span>
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                        )}
                                    {!documents.passport && !documents.birthCertificate && (
                                        <div>Документы отсутствуют</div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

const getFieldLabel = (field) => {
    const labels = {
        name: 'Имя',
        group: 'Группа',
        sport: 'Вид спорта',
        login: 'Логин',
        password: 'Пароль',
        bsp: 'БСП',
        role: 'Роль',
        telephone: 'Телефон',
        email: 'Почта',
        birthday: 'Дата рождения',
    };
    return labels[field] || field;
};

const translateField = (field) => {
    const labels = {
        IdPassport: 'ID паспорта',
        IdUsers: 'ID пользователя',
        Birthplace: 'Место рождения',
        Series: 'Серия',
        Number: 'Номер',
        Issued: 'Кем выдан',
        Data: 'Дата выдачи',
        Code: 'Код подразделения',
        Residency: 'Гражданство',
        Registration: 'Место регистрации',
        Data_Registration: 'Дата регистрации',
        IdCertificate: 'ID свидетельства',
        Series: 'Серия',
        Number: 'Номер',
        IssuedBy: 'Кем выдано',
        DateOfIssue: 'Дата выдачи',
        Birthplace: 'Место рождения',
    };
    return labels[field] || field;
};

export default Profile;
