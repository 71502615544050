// src/components/pages/Propiski.js
import React, { useState, useEffect } from 'react';
import '../../assets/css/propiski.css';
import { FaCheck, FaDownload, FaBell } from 'react-icons/fa'; // Добавили иконку FaBell

const Propiski = ({ addNotification }) => {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState('Все');
  const [loading, setLoading] = useState(true);

  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 20;

  const apiEndpoint = 'https://olimp.uor-ekb.ru/new_admin/propiski_api.php';

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    filterUsers();
  }, [searchTerm, statusFilter, users]);

  const fetchUsers = async () => {
    try {
      const response = await fetch(`${apiEndpoint}?action=list`);
      const data = await response.json();
      if (data.status === 'success') {
        setUsers(data.data);
        setLoading(false);
      } else {
        addNotification({
          icon: 'error',
          title: 'Ошибка',
          subtitle: 'Ошибка при загрузке данных',
          actions: ['OK'],
        });
      }
    } catch (error) {
      console.error('Ошибка при запросе к API:', error);
      addNotification({
        icon: 'error',
        title: 'Ошибка',
        subtitle: 'Ошибка при запросе к API',
        actions: ['OK'],
      });
    }
  };

  const filterUsers = () => {
    let filtered = users;

    if (searchTerm) {
      filtered = filtered.filter((user) =>
        user.fullName.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    if (statusFilter !== 'Все') {
      filtered = filtered.filter((user) => user.status === statusFilter);
    }

    setFilteredUsers(filtered);
    setCurrentPage(0); // Сбрасываем на первую страницу при фильтрации
  };

  const handleMarkReady = async (fileName) => {
    try {
      const response = await fetch(`${apiEndpoint}?action=markReady`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ fileName }),
      });
      const data = await response.json();
      if (data.status === 'success') {
        addNotification({
          icon: 'success',
          title: 'Успех',
          subtitle: 'Документ помечен как готовый',
          actions: ['OK'],
        });
        fetchUsers();
      } else {
        addNotification({
          icon: 'error',
          title: 'Ошибка',
          subtitle: data.message,
          actions: ['OK'],
        });
      }
    } catch (error) {
      console.error('Ошибка при запросе к API:', error);
      addNotification({
        icon: 'error',
        title: 'Ошибка',
        subtitle: 'Ошибка при запросе к API',
        actions: ['OK'],
      });
    }
  };

  const handleDownload = (fileName) => {
    const fileUrl = `https://olimp.uor-ekb.ru/word/${encodeURIComponent(fileName)}`;
    window.open(fileUrl, '_blank');
  };

  const handleSendPush = async (userId) => {
    try {
      const response = await fetch(`${apiEndpoint}?action=sendPush`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ userId }),
      });
      const data = await response.json();
      if (data.status === 'success') {
        addNotification({
          icon: 'success',
          title: 'Успех',
          subtitle: 'Уведомление отправлено пользователю',
          actions: ['OK'],
        });
      } else {
        addNotification({
          icon: 'error',
          title: 'Ошибка',
          subtitle: data.message,
          actions: ['OK'],
        });
      }
    } catch (error) {
      console.error('Ошибка при отправке уведомления:', error);
      addNotification({
        icon: 'error',
        title: 'Ошибка',
        subtitle: 'Ошибка при отправке уведомления',
        actions: ['OK'],
      });
    }
  };

  // Пагинация
  const totalPages = Math.ceil(filteredUsers.length / itemsPerPage);

  const displayedUsers = filteredUsers.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );

  const handlePageChange = (direction) => {
    setCurrentPage((prevPage) => {
      if (direction === 'next' && prevPage < totalPages - 1) {
        return prevPage + 1;
      }
      if (direction === 'prev' && prevPage > 0) {
        return prevPage - 1;
      }
      return prevPage;
    });
  };

  return (
    <div className="propiski">
      <div className="propiski-header">
        <input
          type="text"
          placeholder="Поиск по ФИО"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <div className="status-filters">
          <button
            className={statusFilter === 'Все' ? 'active' : ''}
            onClick={() => setStatusFilter('Все')}
          >
            Все
          </button>
          <button
            className={statusFilter === 'Готово' ? 'active' : ''}
            onClick={() => setStatusFilter('Готово')}
          >
            Готово
          </button>
          <button
            className={statusFilter === 'Не готово' ? 'active' : ''}
            onClick={() => setStatusFilter('Не готово')}
          >
            Не готово
          </button>
        </div>
      </div>

      {loading ? (
        <p className="pcolor">Загрузка...</p>
      ) : (
        <>
          <div className="user-list">
            {displayedUsers.map((user) => (
              <div className="user-card" key={user.id}>
                <div
                  className={`status-circle ${
                    user.status === 'Готово' ? 'ready' : 'not-ready'
                  }`}
                ></div>
                <div className="file-date">
                  {new Date(user.fileTime * 1000).toLocaleString('ru-RU', {
                    day: 'numeric',
                    month: 'long',
                    year: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                  })}
                </div>
                <img
                  src={user.avatar}
                  alt={user.fullName}
                  className="user-avatar"
                />
                <div className="user-info">
                  <h3>{user.fullName}</h3>
                  <p>{`Группа: ${user.group}`}</p>
                  <p>{`Вид спорта: ${user.sport}`}</p>
                </div>
                <div className="user-actions">
                  {user.status !== 'Готово' && (
                    <button
                      className="action-button"
                      onClick={() => handleMarkReady(user.fileName)}
                    >
                      <FaCheck />
                    </button>
                  )}
                  <button
                    className="action-button"
                    onClick={() => handleDownload(user.fileName)}
                  >
                    <FaDownload />
                  </button>
                  {user.pushToken && (
                    <button
                      className="action-button"
                      onClick={() => handleSendPush(user.id)}
                    >
                      <FaBell />
                    </button>
                  )}
                </div>
              </div>
            ))}
          </div>

          {/* Пагинация */}
          <div className="pagination-container">
            <div className="pagination-left">
              <span>Всего: {filteredUsers.length}</span>
            </div>
            <div className="pagination-center">
              <button
                onClick={() => handlePageChange('prev')}
                disabled={currentPage === 0}
              >
                &lt;
              </button>
              <span>
                {currentPage + 1} / {totalPages}
              </span>
              <button
                onClick={() => handlePageChange('next')}
                disabled={currentPage === totalPages - 1 || totalPages === 0}
              >
                &gt;
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Propiski;
