// src/components/AddUserModal.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../assets/css/addUserModal.css'; // Создадим отдельный CSS файл для этого компонента

const AddUserModal = ({ closeModal, addNotification }) => {
  const [groups, setGroups] = useState([]);
  const [roles, setRoles] = useState([]);
  const [sports, setSports] = useState([]);

  const [userInfo, setUserInfo] = useState({
    fio: '',
    group: '',
    role: 'Пользователь',
    bsp: '0',
    login: '',
    password: '',
    sport: '',
    telephone: '',
    email: '',
    birthday: '',
  });

  const [showAdditional, setShowAdditional] = useState(false);
  const [documentType, setDocumentType] = useState('passport');
  const [passportInfo, setPassportInfo] = useState({
    series: '',
    number: '',
    birthDate: '',
    issuedBy: '',
    code: '',
    issueDate: '',
    registration: '',
    registrationDate: '',
  });
  const [birthCertificateInfo, setBirthCertificateInfo] = useState({
    series: '',
    number: '',
    issuedBy: '',
    birthplace: '',
    issueDate: '',
  });

  const [isAutoFilled, setIsAutoFilled] = useState(false);

  useEffect(() => {
    fetchGroups();
    fetchRoles();
    fetchSports();
  }, []);

  useEffect(() => {
    handleAutoFill();
  }, [userInfo.group, userInfo.bsp]);

  const fetchGroups = async () => {
    try {
      const response = await axios.get('https://olimp.uor-ekb.ru/new_admin/get_groups.php');
      if (response.data.status === 'success') {
        setGroups(response.data.groups);
      }
    } catch (error) {
      console.error('Ошибка при получении групп:', error);
    }
  };

  const fetchRoles = async () => {
    try {
      const response = await axios.get('https://olimp.uor-ekb.ru/new_admin/get_roles.php');
      if (response.data.status === 'success') {
        setRoles(response.data.roles);
      }
    } catch (error) {
      console.error('Ошибка при получении ролей:', error);
    }
  };

  const fetchSports = async () => {
    try {
      const response = await axios.get('https://olimp.uor-ekb.ru/new_admin/get_sports.php');
      if (response.data.status === 'success') {
        setSports(response.data.sports);
      }
    } catch (error) {
      console.error('Ошибка при получении видов спорта:', error);
    }
  };

  const handleUserInfoChange = (e) => {
    const { name, value } = e.target;
    setUserInfo((prev) => ({
      ...prev,
      [name]: value,
    }));

    if (name === 'fio' && userInfo.login === '') {
      const surname = value.split(' ')[0];
      setUserInfo((prev) => ({
        ...prev,
        login: surname.toLowerCase(),
      }));
    }

    if (name === 'group' && value === 'Персонал') {
      setUserInfo((prev) => ({
        ...prev,
        bsp: '1',
      }));
    }
  };

  const handleAutoFill = () => {
    if (userInfo.group === 'Персонал' || userInfo.bsp === '1') {
      setIsAutoFilled(true);
      setPassportInfo({
        series: '0000',
        number: '000000',
        birthDate: '1990-01-01',
        issuedBy: 'Администратором',
        code: '000-000',
        issueDate: '1990-01-01',
        registration: 'Училище Олимпийского Резерва',
        registrationDate: '1990-01-01',
      });
    } else {
      setIsAutoFilled(false);
      setPassportInfo({
        series: '',
        number: '',
        birthDate: '',
        issuedBy: '',
        code: '',
        issueDate: '',
        registration: '',
        registrationDate: '',
      });
    }
  };

  const handleShowAdditional = () => {
    setShowAdditional((prev) => !prev);
  };

  const handleGeneratePassword = () => {
    const password = Math.random().toString(36).slice(-6);
    setUserInfo((prev) => ({
      ...prev,
      password,
    }));
  };

  const handleDocumentTypeChange = (type) => {
    setDocumentType(type);
  };

  const handlePassportInfoChange = (e) => {
    const { name, value } = e.target;
    setPassportInfo((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleBirthCertificateInfoChange = (e) => {
    const { name, value } = e.target;
    setBirthCertificateInfo((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    // Собираем данные для отправки на сервер
    const data = {
      ...userInfo,
      passport: isAutoFilled ? passportInfo : documentType === 'passport' ? passportInfo : null,
      birthCertificate:
        isAutoFilled ? null : documentType === 'birthCertificate' ? birthCertificateInfo : null,
    };

    try {
      const response = await axios.post('https://olimp.uor-ekb.ru/new_admin/add_user.php', data);
      if (response.data.status === 'success') {
        addNotification({
          icon: 'success',
          title: 'Успех',
          subtitle: 'Пользователь успешно добавлен.',
          actions: ['OK'],
        });
        closeModal();
      } else {
        addNotification({
          icon: 'error',
          title: 'Ошибка',
          subtitle: response.data.message || 'Ошибка при добавлении пользователя.',
          actions: ['OK'],
        });
      }
    } catch (error) {
      console.error('Ошибка при добавлении пользователя:', error);
      addNotification({
        icon: 'error',
        title: 'Ошибка',
        subtitle: 'Ошибка при добавлении пользователя.',
        actions: ['OK'],
      });
    }
  };

  return (
    <div className={`modal open`}>
      <div className="modal-content">
        <button className="close-button" onClick={closeModal}>
          &times;
        </button>
        <div className="modal-body">
          <div className="left-section">
            <h2>Информация о пользователе</h2>
            <div className="divider"></div>
            <div className="user-additional-info">
              <div className="info-row">
                <label>ФИО</label>
                <input
                  type="text"
                  name="fio"
                  value={userInfo.fio}
                  onChange={handleUserInfoChange}
                />
              </div>
              <div className="info-row">
                <label>Группа</label>
                <select name="group" value={userInfo.group} onChange={handleUserInfoChange}>
                  <option value="">Выберите группу</option>
                  {groups.map((group) => (
                    <option key={group.id} value={group.name}>
                      {group.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="info-row">
                <label>Роль</label>
                <select name="role" value={userInfo.role} onChange={handleUserInfoChange}>
                  {roles.map((role) => (
                    <option key={role.id} value={role.name}>
                      {role.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="info-row">
                <label>БСП</label>
                <select name="bsp" value={userInfo.bsp} onChange={handleUserInfoChange}>
                  <option value="1">БСП да</option>
                  <option value="0">БСП нет</option>
                </select>
              </div>
              <div className="info-row">
                <label>Логин</label>
                <input
                  type="text"
                  name="login"
                  value={userInfo.login}
                  onChange={handleUserInfoChange}
                />
              </div>
              <div className="info-row">
                <label>Пароль</label>
                <input
                  type="text"
                  name="password"
                  value={userInfo.password}
                  onChange={handleUserInfoChange}
                />
                <button className="generate-btn" onClick={handleGeneratePassword}>
                  Генерировать
                </button>
              </div>

              <div className="toggle-additional" onClick={handleShowAdditional}>
                Показать доп. параметры {showAdditional ? '▲' : '▼'}
              </div>

              {showAdditional && (
                <>
                  <div className="info-row">
                    <label>Вид спорта</label>
                    <select name="sport" value={userInfo.sport} onChange={handleUserInfoChange}>
                      <option value="">Выберите вид спорта</option>
                      {sports.map((sport) => (
                        <option key={sport.id} value={sport.title}>
                          {sport.title}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="info-row">
                    <label>Телефон</label>
                    <input
                      type="text"
                      name="telephone"
                      value={userInfo.telephone}
                      onChange={handleUserInfoChange}
                    />
                  </div>
                  <div className="info-row">
                    <label>Email</label>
                    <input
                      type="email"
                      name="email"
                      value={userInfo.email}
                      onChange={handleUserInfoChange}
                    />
                  </div>
                  <div className="info-row">
                    <label>Дата рождения</label>
                    <input
                      type="date"
                      name="birthday"
                      value={userInfo.birthday}
                      onChange={handleUserInfoChange}
                    />
                  </div>
                </>
              )}
            </div>
          </div>

          <div className="right-section">
            {isAutoFilled ? (
              <div className="auto-fill-message">
                Все данные будут автоматически заполнены шаблонным вариантом.
              </div>
            ) : (
              <>
                <h2>Документы пользователя</h2>
                <div className="document-buttons">
                  <button
                    className={documentType === 'passport' ? 'active' : ''}
                    onClick={() => handleDocumentTypeChange('passport')}
                  >
                    Паспорт
                  </button>
                  <button
                    className={documentType === 'birthCertificate' ? 'active' : ''}
                    onClick={() => handleDocumentTypeChange('birthCertificate')}
                  >
                    Свидет. о рождении
                  </button>
                </div>

                {documentType === 'passport' && (
                  <div className="document-content">
                    <div className="info-row">
                      <label>Серия</label>
                      <input
                        type="text"
                        name="series"
                        value={passportInfo.series}
                        onChange={handlePassportInfoChange}
                        maxLength="4"
                      />
                    </div>
                    <div className="info-row">
                      <label>Номер</label>
                      <input
                        type="text"
                        name="number"
                        value={passportInfo.number}
                        onChange={handlePassportInfoChange}
                        maxLength="6"
                      />
                    </div>
                    <div className="info-row">
                      <label>Дата рождения</label>
                      <input
                        type="date"
                        name="birthDate"
                        value={passportInfo.birthDate}
                        onChange={handlePassportInfoChange}
                      />
                    </div>
                    <div className="info-row">
                      <label>Кем выдан</label>
                      <input
                        type="text"
                        name="issuedBy"
                        value={passportInfo.issuedBy}
                        onChange={handlePassportInfoChange}
                      />
                    </div>
                    <div className="info-row">
                      <label>Код органа выдачи</label>
                      <input
                        type="text"
                        name="code"
                        value={passportInfo.code}
                        onChange={handlePassportInfoChange}
                      />
                    </div>
                    <div className="info-row">
                      <label>Дата выдачи</label>
                      <input
                        type="date"
                        name="issueDate"
                        value={passportInfo.issueDate}
                        onChange={handlePassportInfoChange}
                      />
                    </div>
                    <div className="info-row">
                      <label>Прописка</label>
                      <input
                        type="text"
                        name="registration"
                        value={passportInfo.registration}
                        onChange={handlePassportInfoChange}
                      />
                    </div>
                    <div className="info-row">
                      <label>Дата прописки</label>
                      <input
                        type="date"
                        name="registrationDate"
                        value={passportInfo.registrationDate}
                        onChange={handlePassportInfoChange}
                      />
                    </div>
                  </div>
                )}

                {documentType === 'birthCertificate' && (
                  <div className="document-content">
                    <div className="info-row">
                      <label>Серия</label>
                      <input
                        type="text"
                        name="series"
                        value={birthCertificateInfo.series}
                        onChange={handleBirthCertificateInfoChange}
                      />
                    </div>
                    <div className="info-row">
                      <label>Номер</label>
                      <input
                        type="text"
                        name="number"
                        value={birthCertificateInfo.number}
                        onChange={handleBirthCertificateInfoChange}
                      />
                    </div>
                    <div className="info-row">
                      <label>Кем выдано</label>
                      <input
                        type="text"
                        name="issuedBy"
                        value={birthCertificateInfo.issuedBy}
                        onChange={handleBirthCertificateInfoChange}
                      />
                    </div>
                    <div className="info-row">
                      <label>Место рождения</label>
                      <input
                        type="text"
                        name="birthplace"
                        value={birthCertificateInfo.birthplace}
                        onChange={handleBirthCertificateInfoChange}
                      />
                    </div>
                    <div className="info-row">
                      <label>Дата выдачи</label>
                      <input
                        type="date"
                        name="issueDate"
                        value={birthCertificateInfo.issueDate}
                        onChange={handleBirthCertificateInfoChange}
                      />
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        <div className="modal-footer">
          <button className="save-button" onClick={handleSubmit}>
            Добавить пользователя
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddUserModal;
