// src/components/RoleBasedMenu.js
import { FaHome, FaUser, FaCog, FaTasks, FaRegNewspaper, FaFile } from 'react-icons/fa';
import Dashboard from './pages/Dashboard';
import Tasks from './pages/Tasks';
import Settings from './pages/Settings';
import Profile from './pages/Profile';
import NewsManagement from './pages/NewsManagement';
import Propiski from './pages/Propiski';

const RoleBasedMenu = (roleId) => {
  const roles = {
    1: 'user', // Role ID 1 — admin
    2: 'admin',  // Role ID 2 — user
    4: 'pass',  // Role ID 2 — user
  };

  const allMenuItems = [
    { path: '/dashboard', title: 'Главная', icon: FaHome, component: Dashboard, roles: ['admin', 'pass'] },
    { path: '/profile', title: 'Профиль', icon: FaUser, component: Profile, roles: ['admin'] },
    { path: '/news-management', title: 'Новости', icon: FaRegNewspaper, component: NewsManagement, roles: ['admin'] },
    { path: '/propiski', title: 'Прописки', icon: FaFile, component: Propiski, roles: ['admin', 'pass'] },
    { path: '/tasks', title: 'Задачи', icon: FaTasks, component: Tasks, roles: ['admin'] },
    { path: '/settings', title: 'Настройки', icon: FaCog, component: Settings, roles: ['admin'] },
    
    
  ];

  const roleName = roles[roleId];

  return roleName ? allMenuItems.filter((item) => item.roles.includes(roleName)) : [];
};

export default RoleBasedMenu;
