// src/components/NotificationManager.js
import React, { useState, forwardRef, useImperativeHandle } from 'react';
import '../assets/css/styleNotifications.css';
import Notification from './Notification';

const MAX_NOTIFICATIONS = 4;

const NotificationManager = forwardRef((props, ref) => {
  const [notifications, setNotifications] = useState([]);

  useImperativeHandle(ref, () => ({
    addNotification(notification) {
      setNotifications((prevNotifications) => {
        const newNotification = {
          ...notification,
          id: Date.now(),
        };
        let newNotifications = [...prevNotifications, newNotification];

        // Если превышено максимальное количество уведомлений, удаляем самое старое
        if (newNotifications.length > MAX_NOTIFICATIONS) {
          newNotifications.shift();
        }

        return newNotifications;
      });
    },
  }));

  const removeNotification = (id) => {
    setNotifications((prevNotifications) =>
      prevNotifications.filter((n) => n.id !== id)
    );
  };

  return (
    <div className="notifications">
      <svg display="none">
        {/* Добавьте здесь ваши SVG-символы */}
        <symbol id="error" viewBox="0 0 32 32">
          <circle r="15" cx="16" cy="16" fill="none" stroke="hsl(13,90%,55%)" strokeWidth="2" />
          <line
            x1="10"
            y1="10"
            x2="22"
            y2="22"
            stroke="hsl(13,90%,55%)"
            strokeWidth="2"
            strokeLinecap="round"
          />
          <line
            x1="22"
            y1="10"
            x2="10"
            y2="22"
            stroke="hsl(13,90%,55%)"
            strokeWidth="2"
            strokeLinecap="round"
          />
        </symbol>
        <symbol id="success" viewBox="0 0 32 32">
          <circle r="15" cx="16" cy="16" fill="none" stroke="hsl(93,90%,40%)" strokeWidth="2" />
          <polyline
            points="9,18 13,22 23,12"
            fill="none"
            stroke="hsl(93,90%,40%)"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </symbol>
        {/* Добавьте другие символы SVG, если необходимо */}
      </svg>

      {notifications.map((note, index) => (
        <Notification
          key={note.id}
          note={note}
          index={index}
          removeNotification={removeNotification}
        />
      ))}
    </div>
  );
});

export default NotificationManager;
