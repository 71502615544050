// src/App.js
import React, { useState, useRef, useEffect } from 'react';
import Login from './components/Login';
import NotificationManager from './components/NotificationManager';
import MainPage from './components/MainPage';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const notificationManagerRef = useRef();

  // Устанавливаем тему при загрузке приложения
  useEffect(() => {
    const savedTheme = localStorage.getItem('theme');
    if (savedTheme === 'dark') {
      document.documentElement.classList.add('dark');
    }
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      setUser(JSON.parse(storedUser));
      setIsAuthenticated(true);
    }
    setLoading(false);
  }, []);

  const addNotification = (notification) => {
    notificationManagerRef.current.addNotification(notification);
  };

  const handleLoginSuccess = (user) => {
    setUser(user);
    setIsAuthenticated(true);
    localStorage.setItem('user', JSON.stringify(user));
    addNotification({
      icon: 'success',
      title: 'Welcome!',
      subtitle: `Welcome, ${user.name}!`,
      actions: ['OK'],
    });
  };

  const handleLogout = () => {
    setUser(null);
    setIsAuthenticated(false);
    localStorage.removeItem('user');
    addNotification({
      icon: 'info',
      title: 'Logged Out',
      subtitle: 'You have successfully logged out.',
      actions: ['OK'],
    });
  };

  if (loading) {
    return null;
  }

  return (
    <div className="App">
      <NotificationManager ref={notificationManagerRef} />
      {isAuthenticated ? (
        <MainPage user={user} addNotification={addNotification} onLogout={handleLogout} />
      ) : (
        <Login addNotification={addNotification} onLoginSuccess={handleLoginSuccess} />
      )}
    </div>
  );
}

export default App;
