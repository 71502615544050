// src/components/Sidebar.js
import React from 'react';
import { NavLink } from 'react-router-dom';
import RoleBasedMenu from './RoleBasedMenu';
import '../assets/css/sidebar.css';

const Sidebar = ({ userRole, isCollapsed }) => {
  const menuItems = RoleBasedMenu(userRole);

  return (
    <div className={`app-sidebar ${isCollapsed ? 'collapsed' : 'expanded'}`}>
      {menuItems.map((item, index) => (
        <NavLink
          key={index}
          to={item.path}
          className={({ isActive }) =>
            `app-sidebar-link ${isActive ? 'active' : ''}`
          }
          end
        >
          <item.icon className="link-icon" />
          <span className="link-text">{item.title}</span>
        </NavLink>
      ))}
    </div>
  );
};

export default Sidebar;
