// src/components/Dashboard.js
import React, { useState, useEffect } from 'react';
import { FaUser, FaChartLine, FaUsers, FaGlobe } from 'react-icons/fa';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import '../../assets/css/dashboard.css';

const Dashboard = () => {
  const [stats, setStats] = useState({
    users: { total: 0, with_room_number: 0, with_push: 0, with_passport_or_id: 0 },
    scanner_logs: { access_granted_today: 0, hourly_access: [], latest_users: [] },
  });
  const [chartData, setChartData] = useState([]);
  const apiEndpoint = 'https://olimp.uor-ekb.ru/new_admin/stats.php';

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const response = await fetch(apiEndpoint);
        const data = await response.json();

        if (data.status === "success") {
          setStats(data.data);

          // Форматируем данные для графика с часами от 00 до 23
          const hourlyData = Array.from({ length: 24 }, (_, hour) => {
            const formattedHour = hour.toString().padStart(2, '0');
            const entry = data.data.scanner_logs.hourly_access.find(e => e.hour === `${formattedHour}:00`);
            return {
              name: formattedHour,
              value: entry ? entry.access_count : 0,
            };
          });
          setChartData(hourlyData);
        }
      } catch (error) {
        console.error("Ошибка при запросе к API:", error);
      }
    };

    fetchStats();
    const interval = setInterval(fetchStats, 2000);

    return () => clearInterval(interval);
  }, []);

  // Определяем максимальное значение для оси Y с добавлением 50
  const maxYValue = Math.max(...chartData.map(data => data.value)) + 50;

  return (
    <div className="dashboard">
      {/* Верхние три блока статистики */}
      <div className="stats-row">
        <div className="stats-card">
          <FaUser className="icon" />
          <div className='flexik'>
            <h3>Всего пользователей</h3>
            <p>{stats.users.total}</p>
          </div>
        </div>
        <div className="stats-card">
          <FaChartLine className="icon" />
          <div className='flexik'>
            <h3>Пользователи в общежитии</h3>
            <p>{stats.users.with_room_number}</p>
          </div>
        </div>
        <div className="stats-card">
          <FaUsers className="icon" />
          <div className='flexik'>
            <h3>Пользователи с уведомлениями</h3>
            <p>{stats.users.with_push}</p>
          </div>
        </div>
      </div>

      {/* График и список пользователей */}
      <div className="main-section">
        <div className="chart-card large-card">
          <h3>Статистика посещений по часам</h3>
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={chartData}>
              <CartesianGrid vertical={true} horizontal={false} stroke="#555" strokeWidth={1} />
              <XAxis dataKey="name" />
              <YAxis domain={[0, maxYValue]} />
              <Tooltip />
              <Line type="monotone" dataKey="value" stroke="#8884d8" strokeWidth={3} dot={false} />
            </LineChart>
          </ResponsiveContainer>
        </div>

        <div className="user-list-card small-card">
          <h3>Последние пользователи</h3>
          {stats.scanner_logs.latest_users.map((user, index) => (
            <div className="user-item" key={index}>
              <img 
                src={user.avatar} 
                alt={user.fio} 
                className="user-icon" 
              />
              <div>
                <p>{user.fio}</p>
                <small>{new Date(user.timestamp).toLocaleTimeString()}</small>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Нижние два блока статистики */}
      <div className="stats-row">
        <div className="stats-card">
          <FaGlobe className="icon" />
          <div className='flexik'>
            <h3>Пользователи с видом спорта</h3>
            <p>{stats.users.with_passport_or_id}</p>
          </div>
        </div>
        <div className="stats-card">
          <FaChartLine className="icon" />
          <div className='flexik'>
            <h3>Посещения</h3>
            <p>{stats.scanner_logs.access_granted_today}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
