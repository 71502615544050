// src/components/MainPage.js
import React, { useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Sidebar from './Sidebar';
import Header from './Header';
import RoleBasedMenu from './RoleBasedMenu';
import '../assets/css/mainpage.css';

const MainPage = ({ user, addNotification, onLogout }) => {
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(() => {
    const savedState = localStorage.getItem('isSidebarCollapsed');
    return savedState !== null ? JSON.parse(savedState) : true;
  });

  const toggleSidebar = () => {
    setIsSidebarCollapsed((prevState) => {
      const newState = !prevState;
      localStorage.setItem('isSidebarCollapsed', JSON.stringify(newState));
      return newState;
    });
  };

  const accessibleRoutes = RoleBasedMenu(user.role);

  return (
    <div className="app-wrapper">
      <Header
        userName={user.name}
        userAvatar={user.Avatar}
        addNotification={addNotification}
        onLogout={onLogout}
        isSidebarCollapsed={isSidebarCollapsed}
        toggleSidebar={toggleSidebar}
      />
      <div className="app-container">
        <Sidebar userRole={user.role} isCollapsed={isSidebarCollapsed} />
        <div className="app-content">
          <Routes>
            {accessibleRoutes.map((item) => (
              <Route
                key={item.path}
                path={item.path}
                element={<item.component addNotification={addNotification} />}
              />
            ))}
            <Route path="*" element={<Navigate to="/dashboard" replace />} />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default MainPage;
