// src/components/pages/NewsManagement.js
import React, { useState, useEffect } from 'react';
import '../../assets/css/newsManagement.css';
import { FaEdit, FaTrash, FaPlus } from 'react-icons/fa';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const NewsManagement = ({ addNotification }) => {
  const [newsList, setNewsList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedNews, setSelectedNews] = useState(null);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

  const [groups, setGroups] = useState([]);
  const [sports, setSports] = useState([]);

  const [formData, setFormData] = useState({
    id: null,
    title: '',
    description: '',
    idGroup: null,
    idSport: null,
  });

  const apiEndpoint = 'https://olimp.uor-ekb.ru/new_admin/news_api.php';
  const groupsEndpoint = 'https://olimp.uor-ekb.ru/new_admin/get_groups.php';
  const sportsEndpoint = 'https://olimp.uor-ekb.ru/new_admin/get_sports.php';

  useEffect(() => {
    fetchNews();
    fetchGroups();
    fetchSports();
  }, []);

  const fetchNews = async () => {
    try {
      const response = await fetch(`${apiEndpoint}?action=list`);
      const data = await response.json();
      if (data.status === 'success') {
        setNewsList(data.data);
        setLoading(false);
      } else {
        addNotification({
          icon: 'error',
          title: 'Ошибка',
          subtitle: 'Ошибка при загрузке новостей',
          actions: ['OK'],
        });
      }
    } catch (error) {
      console.error('Ошибка при запросе к API:', error);
      addNotification({
        icon: 'error',
        title: 'Ошибка',
        subtitle: 'Ошибка при запросе к API',
        actions: ['OK'],
      });
    }
  };

  const fetchGroups = async () => {
    try {
      const response = await fetch(groupsEndpoint);
      const data = await response.json();
      if (data.status === 'success') {
        setGroups(data.groups);
      } else {
        console.error('Ошибка при загрузке групп');
      }
    } catch (error) {
      console.error('Ошибка при запросе групп:', error);
    }
  };

  const fetchSports = async () => {
    try {
      const response = await fetch(sportsEndpoint);
      const data = await response.json();
      if (data.status === 'success') {
        setSports(data.sports);
      } else {
        console.error('Ошибка при загрузке видов спорта');
      }
    } catch (error) {
      console.error('Ошибка при запросе видов спорта:', error);
    }
  };

  const handleDelete = async (id) => {
    if (!window.confirm('Вы уверены, что хотите удалить эту новость?')) return;

    try {
      const response = await fetch(`${apiEndpoint}?action=delete`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ id }),
      });
      const data = await response.json();
      if (data.status === 'success') {
        addNotification({
          icon: 'success',
          title: 'Успех',
          subtitle: 'Новость успешно удалена',
          actions: ['OK'],
        });
        fetchNews();
      } else {
        addNotification({
          icon: 'error',
          title: 'Ошибка',
          subtitle: 'Ошибка при удалении новости',
          actions: ['OK'],
        });
      }
    } catch (error) {
      console.error('Ошибка при запросе к API:', error);
      addNotification({
        icon: 'error',
        title: 'Ошибка',
        subtitle: 'Ошибка при запросе к API',
        actions: ['OK'],
      });
    }
  };

  const handleView = (newsItem) => {
    setSelectedNews(newsItem);
    setIsViewModalOpen(true);
  };

  const handleEdit = (newsItem) => {
    setFormData({
      id: newsItem.id,
      title: newsItem.title,
      description: newsItem.description,
      idGroup: newsItem.idGroup,
      idSport: newsItem.idSport,
    });
    setIsEditModalOpen(true);
  };

  const handleCreate = () => {
    setFormData({
      id: null,
      title: '',
      description: '',
      idGroup: null,
      idSport: null,
    });
    setIsCreateModalOpen(true);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const action = formData.id ? 'edit' : 'add';
    const payload = {
      id: formData.id,
      title: formData.title,
      description: formData.description,
      idGroup: formData.idGroup,
      idSport: formData.idSport,
    };

    try {
      const response = await fetch(`${apiEndpoint}?action=${action}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
      });
      const data = await response.json();
      if (data.status === 'success') {
        addNotification({
          icon: 'success',
          title: 'Успех',
          subtitle: `Новость успешно ${action === 'add' ? 'добавлена' : 'обновлена'}`,
          actions: ['OK'],
        });
        fetchNews();
        setIsEditModalOpen(false);
        setIsCreateModalOpen(false);
      } else {
        addNotification({
          icon: 'error',
          title: 'Ошибка',
          subtitle: `Ошибка при ${action === 'add' ? 'добавлении' : 'обновлении'} новости`,
          actions: ['OK'],
        });
      }
    } catch (error) {
      console.error('Ошибка при запросе к API:', error);
      addNotification({
        icon: 'error',
        title: 'Ошибка',
        subtitle: 'Ошибка при запросе к API',
        actions: ['OK'],
      });
    }
  };

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link', 'image'],
      ['clean'],
    ],
  };

  return (
    <div className="news-management">
      {loading ? (
        <p className="pcolor">Загрузка...</p>
      ) : (
        <div className="news-list">
          {newsList.map((newsItem) => (
            <div
              className="news-card"
              key={newsItem.id}
              onClick={() => handleView(newsItem)}
            >
              <div className="news-content">
                <h3>{newsItem.title}</h3>
                <div
                  className="news-description"
                  dangerouslySetInnerHTML={{ __html: newsItem.description }}
                ></div>
                <div className="news-meta">
                  <span>{`Группа: ${newsItem.group}`}</span>
                  <span>{`Вид спорта: ${newsItem.sport}`}</span>
                  <span>{`Дата: ${new Date(newsItem.date).toLocaleDateString(
                    'ru-RU',
                    {
                      day: 'numeric',
                      month: 'long',
                      year: 'numeric',
                    }
                  )}`}</span>
                </div>
              </div>
              <div
                className="news-actions"
                onClick={(e) => e.stopPropagation()}
              >
                <button className="edit-btn" onClick={() => handleEdit(newsItem)}>
                  <FaEdit />
                </button>
                <button
                  className="delete-btn"
                  onClick={() => handleDelete(newsItem.id)}
                >
                  <FaTrash />
                </button>
              </div>
            </div>
          ))}
        </div>
      )}

      {/* Плавающая кнопка для создания новости */}
      <button className="floating-button" onClick={handleCreate}>
        <FaPlus />
      </button>

      {/* Модальное окно просмотра новости */}
      {isViewModalOpen && (
        <div
          className="modal-overlay"
          onClick={() => setIsViewModalOpen(false)}
        >
          <div
            className="modal-content"
            onClick={(e) => e.stopPropagation()}
          >
            <h2>{selectedNews.title}</h2>
            <div
              dangerouslySetInnerHTML={{ __html: selectedNews.description }}
            ></div>
          </div>
        </div>
      )}

      {/* Модальное окно создания/редактирования новости */}
      {(isEditModalOpen || isCreateModalOpen) && (
        <div
          className="modal-overlay"
          onClick={() => {
            setIsEditModalOpen(false);
            setIsCreateModalOpen(false);
          }}
        >
          <div
            className="modal-content"
            onClick={(e) => e.stopPropagation()}
          >
            <h2>{formData.id ? 'Редактировать новость' : 'Создать новость'}</h2>
            <form onSubmit={handleFormSubmit}>
              <label>
         
                <input
                  type="text" placeholder='Заголовок:'
                  value={formData.title}
                  onChange={(e) =>
                    setFormData({ ...formData, title: e.target.value })
                  }
                  required
                />
              </label>
             
              <label>
                Описание:
                <ReactQuill
                  value={formData.description}
                  onChange={(value) =>
                    setFormData({ ...formData, description: value })
                  }
                  modules={modules}
                />
              </label>
              <div className="select-container">
                <label>
               
                  <select className='selectcustom'
                    value={formData.idGroup !== null ? formData.idGroup : ''}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        idGroup: e.target.value !== '' ? e.target.value : null,
                      })
                    }
                  >
                    <option value="">Для всех групп</option>
                    {groups.map((group) => (
                      <option key={group.id} value={group.id}>
                        {group.name}
                      </option>
                    ))}
                  </select>
                </label>
                <label>
                 
                  <select className='selectcustom'
                    value={formData.idSport !== null ? formData.idSport : ''}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        idSport: e.target.value !== '' ? e.target.value : null,
                      })
                    }
                  >
                    <option value="">Для всех вид. спорта</option>
                    {sports.map((sport) => (
                      <option key={sport.id} value={sport.id}>
                        {sport.title}
                      </option>
                    ))}
                  </select>
                </label>
              </div>
              <div className="modal-buttons">
                <button type="submit" className="save-button">
                  Сохранить
                </button>
                <button
                  type="button"
                  className="cancel-button"
                  onClick={() => {
                    setIsEditModalOpen(false);
                    setIsCreateModalOpen(false);
                  }}
                >
                  Отмена
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default NewsManagement;
