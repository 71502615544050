// src/components/Notification.js
import React, { useEffect, useRef, useState } from 'react';

const Notification = ({ note, index, removeNotification }) => {
  const [timeLeft, setTimeLeft] = useState(5); // Начальное время 5 секунд
  const [isClosing, setIsClosing] = useState(false);
  const timerRef = useRef();
  const animationTimerRef = useRef();

  useEffect(() => {
    // Запускаем интервал для обновления таймера
    timerRef.current = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(timerRef.current);
          startClosing(); // Начинаем закрытие уведомления
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => {
      clearInterval(timerRef.current);
      clearTimeout(animationTimerRef.current);
    };
  }, []); // Пустой массив зависимостей, чтобы эффект запускался один раз

  const startClosing = () => {
    setIsClosing(true);
    // Ждем окончания анимации перед удалением уведомления
    animationTimerRef.current = setTimeout(() => {
      removeNotification(note.id);
    }, 300); // Длительность анимации в миллисекундах (должна совпадать с CSS)
  };

  const handleClose = () => {
    clearInterval(timerRef.current);
    startClosing();
  };

  return (
    <div
      className={`notification ${isClosing ? 'notification--out' : ''}`}
      style={{ transform: `translateY(${index * 110}%)` }}
    >
      <div className="notification__box">
        <div className="notification__content">
          <div className="notification__icon">
            <svg className="notification__icon-svg" aria-label={note.icon}>
              <use href={`#${note.icon}`} />
            </svg>
          </div>
          <div className="notification__text">
            <div className="notification__text-title">{note.title}</div>
            <div className="notification__text-subtitle">{note.subtitle}</div>
          </div>
        </div>
        <div className="notification__btns">
          {note.actions.map((action, idx) => (
            <button
              key={idx}
              className="notification__btn"
              onClick={handleClose}
            >
              <span className="notification__btn-text">{action}</span>
              <div className="timer">
                <svg className="timer__circle" viewBox="0 0 32 32">
                  <circle
                    cx="16"
                    cy="16"
                    r="14"
                    strokeDasharray="88"
                    strokeDashoffset={(1 - timeLeft / 5) * 88}
                  />
                  <text x="16" y="21" textAnchor="middle" fontSize="10px">
                    {timeLeft}
                  </text>
                </svg>
              </div>
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Notification;
