import React from 'react';

const Settings = () => {
    return (
        <div>
            <h2>Панель управления</h2>
            <p>Добро пожаловать в панель управления!</p>
        </div>
    );
};

export default Settings;
