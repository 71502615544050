// src/components/Header.js

import React, { useState, useEffect } from 'react';
import '../assets/css/header.css';
import { FaSearch, FaBell, FaPlus, FaMoon, FaSun, FaSignOutAlt } from 'react-icons/fa';
import AddUserModal from './AddUserModal'; // Импортируем новый компонент

const Header = ({
  userName,
  userAvatar,
  addNotification,
  onLogout,
  isSidebarCollapsed,
  toggleSidebar,
}) => {
  const [isDarkMode, setIsDarkMode] = useState(() => {
    return localStorage.getItem('theme') === 'dark';
  });
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isAddUserModalOpen, setIsAddUserModalOpen] = useState(false); // Добавлено состояние для модального окна

  useEffect(() => {
    document.documentElement.classList.toggle('dark', isDarkMode);
    localStorage.setItem('theme', isDarkMode ? 'dark' : 'light');
  }, [isDarkMode]);

  const handleLogout = () => {
    addNotification({
      icon: 'info',
      title: 'Выход',
      subtitle: 'Вы успешно вышли',
      actions: ['OK'],
    });
    onLogout();
  };

  const toggleDarkMode = () => {
    setIsDarkMode((prev) => !prev);
  };

  const toggleMenu = () => {
    setIsMenuOpen((prev) => !prev);
  };

  const openAddUserModal = () => {
    setIsAddUserModalOpen(true);
  };

  const closeAddUserModal = () => {
    setIsAddUserModalOpen(false);
  };

  return (
    <>
      <div className="app-header">
        <div className="app-header-left">
          <span
            className={`app-icon ${isSidebarCollapsed ? 'rotated' : ''}`}
            onClick={toggleSidebar}
          ></span>
          <p className="app-name">Панель управления</p>
          <div className="search-wrapper">
            <input className="search-input" type="text" placeholder="Поиск в разработке.." />
            <FaSearch />
          </div>
        </div>
        <div className="app-header-right">
          <button className="mode-switch" title="Переключить тему" onClick={toggleDarkMode}>
            {isDarkMode ? <FaSun /> : <FaMoon />}
          </button>
          <button className="add-btn" title="Добавить нового пользователя" onClick={openAddUserModal}>
            <FaPlus className="btn-icon" />
          </button>
          <button className="notification-btn">
            <FaBell />
          </button>
          <div className="profile-menu">
            <button className="profile-btn" onClick={toggleMenu}>
              <img src={userAvatar} alt="Профиль" />
              <span>{userName}</span>
            </button>
            {isMenuOpen && (
              <div className="dropdown-menu">
                <button className="dropdown-item" onClick={handleLogout}>
                  <FaSignOutAlt /> Выйти
                </button>
              </div>
            )}
          </div>
        </div>
      </div>

      {isAddUserModalOpen && (
        <AddUserModal
          closeModal={closeAddUserModal}
          addNotification={addNotification}
        />
      )}
    </>
  );
};

export default Header;
